<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <app-title
        :title="`Liquidacion de pago mes de ${this.$moment().format('MMMM')}`"
        subtitle="Resumen detallado de los pagos mensuales"
      >
      </app-title>
      <v-layout row wrap>
        <v-flex d-flex xs12 sm12 md6 lg4 xl4>
          <v-card>
            <v-list two-line subheader>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title
                    >Ventas {{ this.$moment().format('MMMM') }}
                    <a class="mx-1" @click="visible = !visible">({{ rows.length }} pedidos)</a></v-list-tile-title
                  >
                  <v-list-tile-sub-title>Pedidos entregados</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  {{ montoTotal | toPrice }}
                </v-list-tile-action>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title
                    >Insumos {{ this.$moment().format('MMMM') }}
                    <a class="mx-1" @click="visibleDiscount = !visibleDiscount"
                      >({{ this.discounts.length }} insumos)</a
                    ></v-list-tile-title
                  >
                  <v-list-tile-sub-title>---</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action> - {{ descuentoTotal | toPrice }} </v-list-tile-action>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>Comisión por ventas</v-list-tile-title>
                  <v-list-tile-sub-title>{{ user.store.discount }}% del total de ventas</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action> - {{ comision | toPrice }} </v-list-tile-action>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>IVA Comisión</v-list-tile-title>
                  <v-list-tile-sub-title>19% de {{ ((montoTotal * 10) / 100) | toPrice }}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action> - {{ comisionIVA | toPrice }} </v-list-tile-action>
              </v-list-tile>
              <v-divider></v-divider>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>Total a pagar</v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  {{ pagarTotal | toPrice }}
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
          </v-card>
        </v-flex>
        <v-flex d-flex xs12 sm12 md6 lg8 xl8>
          <v-card class="accent">
            <v-card-text>
              <LineChart :chart-data="dataChart" :options="optionsData" :style="myStyle"></LineChart>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex v-if="visible" xs12>
          <v-card>
            <v-card-title class="title">Pedidos entregados {{ this.$moment().format('MMMM') }}</v-card-title>
            <v-data-table
              :headers="[
                { text: 'Pedido', align: 'left', sortable: false },
                { text: 'Cliente', align: 'left', sortable: false },
                { text: 'Total', align: 'right', sortable: false },
                { text: 'Comuna', sortable: false },
                { text: 'Horario de entrega', sortable: false }
              ]"
              :items="rows"
              :rows-per-page-items="rowsPerPage"
              class="elevation-0"
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-left">
                  <v-btn
                    depressed
                    color="accent"
                    target="_blank"
                    :to="{ name: 'orderIndex', params: { id: props.item.id } }"
                    small
                  >
                    {{ props.item.id }}
                  </v-btn>
                </td>
                <td class="text-xs-left" v-html="props.item.name"></td>
                <td class="text-xs-right">{{ props.item.valor | toPrice }}</td>
                <td class="text-xs-left" v-html="props.item.comuna"></td>
                <td class="text-xs-left" v-html="props.item.horario"></td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
        <v-flex v-if="visibleDiscount" xs12>
          <v-card>
            <v-card-title class="title">Listados de descuentos</v-card-title>
            <v-data-table
              :headers="[
                { text: 'Solicitud', align: 'left', sortable: false },
                { text: 'Nombre', align: 'left', sortable: false },
                { text: 'Cantidad', align: 'right', sortable: false },
                { text: 'Precio', align: 'right', sortable: false },
                { text: 'Total', align: 'right', sortable: false }
              ]"
              :items="discounts"
              :rows-per-page-items="rowsPerPage"
              class="elevation-0"
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-left"># {{ props.item.solicitud }}</td>
                <td class="text-xs-left" v-html="props.item.name"></td>
                <td class="text-xs-right">{{ props.item.quantity }}</td>
                <td class="text-xs-right">{{ props.item.price | toPrice }}</td>
                <td class="text-xs-right">{{ props.item.value | toPrice }}</td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <v-data-table
              :headers="header"
              :items="facturacion"
              class="elevation-0"
              no-data-text="No hay liquidaciones ingresadas"
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-left">{{ props.item.month }} / {{ props.item.year }}</td>
                <td class="text-xs-right">
                  {{ props.item.pagarTotal | toPrice }}
                </td>
                <td class="text-xs-right">
                  {{ props.item.comision | toPrice }}
                </td>
                <td class="text-xs-right">
                  {{ props.item.comisionIVA | toPrice }}
                </td>
                <td class="text-xs-right">
                  {{ props.item.descuentoTotal | toPrice }}
                </td>
                <td class="text-xs-right">
                  {{ props.item.otros | toPrice }}
                </td>
                <td class="text-xs-right">
                  {{ props.item.pagarTotal | toPrice }}
                </td>
                <td class="text-xs-right" @click="id = props.item.id">
                  <v-btn
                    v-if="props.item.document && props.item.factura"
                    title="Documento"
                    class="ma-0"
                    color="accent"
                    :href="props.item.document"
                    target="_blank"
                    flat
                    icon
                  >
                    <v-icon> cloud_download </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="props.item.transfer && props.item.factura"
                    title="Trasnferencia"
                    class="ma-0"
                    color="secondary"
                    :href="props.item.transfer"
                    target="_blank"
                    flat
                    icon
                  >
                    <v-icon> cloud_download </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="props.item.factura"
                    title="Factura"
                    class="ma-0"
                    color="error"
                    :href="props.item.factura"
                    target="_blank"
                    flat
                    icon
                  >
                    <v-icon> cloud_download </v-icon>
                  </v-btn>
                  <FileReader
                    v-if="!props.item.factura"
                    name="Subir Factura"
                    color="error"
                    :flat="true"
                    :block="false"
                    :get-file="getFile"
                    @load="loadFile($event, props.item.id)"
                  >
                  </FileReader>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import LineChart from '../useful/lineChartt'
import appTitle from '../useful/title.vue'
import FileReader from '../useful/FileReader.vue'
import { GET_API, MI_STORE } from '../../config'

export default {
  name: 'StoreStatistics',
  components: { appTitle, LineChart, FileReader },
  data() {
    return {
      id: 0,
      file: '',
      dataChart: null,
      optionsData: {
        hover: {
          // Overrides the global setting
          mode: 'index'
        },
        line: {
          backgroundColor: '#000000'
        },
        legend: {
          labels: {
            fontColor: '#000000',
            fontSize: 12
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: 'white',
                fontSize: 8,
                suggestedMin: 0
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                fontColor: 'white',
                fontSize: 12,
                stepSize: 1,
                beginAtZero: true
              }
            }
          ]
        },
        reponsive: true,
        maintainAspectRatio: false
      },
      dateStart: this.$moment()
        .subtract(30, 'days')
        .format('YYYY-MM-DD'),
      dateEnd: this.$moment().format('YYYY-MM-DD'),
      loading: false,
      type: 3,
      items: [{ id: 1, title: 'Productos - Variacion precios' }],
      header: [
        { text: 'Mes', align: 'left', sortable: false },
        { text: 'Ventas', align: 'right', sortable: false },
        { text: 'Comision 10%', align: 'right', sortable: false },
        { text: 'IVA', align: 'right', sortable: false },
        { text: 'Insumos', align: 'right', sortable: false },
        { text: 'Otros', align: 'right', sortable: false },
        { text: 'Total a pagar', align: 'right', sortable: false },
        {
          text: 'Documentos',
          align: 'right',
          value: 'highPrice',
          sortable: false
        }
      ],
      rows: [],
      facturacion: [],
      discounts: [],
      pagination: {
        sortBy: 'variacion',
        rowsPerPage: 50
      },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      totalRow: 0,
      visible: false,
      visibleDiscount: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    datesDay() {
      const days = this._.groupBy(this.rows, 'date_shipping')
      const daysReturn = []
      this._.forEach(days, (value, key) => {
        daysReturn.push(this.$moment.unix(key).format('dddd DD'))
      })
      return daysReturn
    },
    dataDay() {
      const days = this._.groupBy(this.rows, 'date_shipping')
      const daysReturn = []
      this._.forEach(days, value => {
        daysReturn.push(this._.sumBy(value, 'valor'))
      })
      return daysReturn
    },
    pagarTotal() {
      return this.montoTotal - (this.descuentoTotal + this.comision + this.comisionIVA)
    },
    montoTotal() {
      return this._.sumBy(this.rows, 'valor')
    },
    comisionIVA() {
      return parseInt(((this.comision * 19) / 100).toFixed(0), 10)
    },
    comision() {
      return parseInt(((this.montoTotal * 10) / 100).toFixed(0), 10)
    },
    descuentoTotal() {
      return this._.sumBy(this.discounts, 'value')
    }
  },
  watch: {
    pagination(e) {
      console.log(e)
    },
    type() {
      this.rows = []
      this.getProductsPrice()
    }
  },
  mounted() {
    this.getProductsPrice()
  },
  methods: {
    async getProductsPrice() {
      this.loading = true
      const res = await this.$http.get(
        `${MI_STORE}/statistics/liquidacion?${this.$qs.stringify({
          type: this.type,
          filter: {
            dateStart: this.dateStart,
            dateEnd: this.dateEnd
          }
        })}`
      )
      if (this.type === 1) {
        this.loading = false
        this.rows = res.data
      } else if (this.type === 2) {
        this.rows = res.data
      } else if (this.type === 3) {
        this.rows = res.data.orders
        this.discounts = res.data.discount
        this.facturacion = res.data.facturacion
        this.setGrafico(this.datesDay, this.dataDay)
      }
    },
    async getFile(file) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('p', 'uploadFileFactura')
      formData.append('id', this.id)
      try {
        await this.$http.post(`${MI_STORE}/statistics/liquidacion`, formData, {
          headers: { 'content-type': 'multipart/form-data' }
        })
        this.getProductsPrice()
        this.$store.dispatch('changeSnack', { active: true, text: 'Subido correctamente' })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente...'
        })
      }
    },
    loadFile(e, id) {
      this.file = e
      this.id = id
    },
    setGrafico(labels, data) {
      this.dataChart = {
        labels,
        datasets: [
          {
            label: 'Pedidos',
            borderColor: '#fff',
            showLine: true,
            spanGaps: true,
            data,
            lineTension: 0.4,
            scaleStepWidth: 1
          }
        ]
      }
    },
    minutesSecons(seconds) {
      return this.$moment.utc(seconds * 1000).format('HH:mm:ss')
    },
    colorProductsVariation(value) {
      if (value <= 0) {
        return 'error'
      }
      return ''
    },
    colorProductsVariationText(value) {
      if (value <= 0) {
        return 'white'
      }
      return 'black'
    }
  }
}
</script>

<style></style>
